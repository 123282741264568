


























import { computed, defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    pickerAttrs: {
      type: Object,
      required: false,
      default: () => ({})
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  setup(props, { emit }) {
    const menu = ref<any>(null)

    const model = computed({
      get: () => props.value,
      set: (value) => emit('input', value)
    })

    const state = reactive({
      menu: false,
    })

    const inputRules = computed(() => ([...props.rules]))

    return { menu, model, state, inputRules }
  },
})
